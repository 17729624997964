import LoyaltyIcon from '@mui/icons-material/Loyalty';
import SubscriptionList from './SubscriptionList';
import SubscriptionShow from './SubscriptionShow';

export default {
  list: SubscriptionList,
  show: SubscriptionShow,
  icon: LoyaltyIcon,
  recordRepresentation: (record: any) => `${record.id}`,
};
