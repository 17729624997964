import {
  Datagrid,
  DateField,
  List,
  ReferenceInput,
  ReferenceOneField,
  SearchInput,
  SelectInput,
  TextField,
} from 'react-admin';

import OrderViewEmbedded from './OrderViewEmbedded';

const OrderList = () => {
  return (
    <List
      filters={[
        <ReferenceInput alwaysOn source="user_id" reference="user" label="User">
          <SelectInput source="display_name" />
        </ReferenceInput>,
        <SearchInput source="name,confirmation_number" alwaysOn />,
      ]}
      sort={{ field: 'processed_at', order: 'DESC' }}
    >
      <Datagrid expand={<OrderViewEmbedded />} rowClick="expand" optimized bulkActionButtons={false}>
        <TextField source="id" />
        <TextField source="name" label="Name" />
        <TextField source="confirmation_number" label="Confirmation" />
        <ReferenceOneField source="customer_id" reference="user" target="id" label="User" link="show">
          <TextField source="display_name" />
        </ReferenceOneField>

        <DateField source="processed_at" showTime locales="de-AT" />
      </Datagrid>
    </List>
  );
};

export default OrderList;
