/* eslint react/jsx-key: off */
import PropTypes from 'prop-types';
import { BooleanField, DateField, Identifier, ReferenceField, Show, SimpleShowLayout, TextField } from 'react-admin';

const OrderViewEmbedded = ({ id }: { id?: Identifier }) => {
  return (
    <Show id={id} queryOptions={{ refetchInterval: 5000 }}>
      <SimpleShowLayout>
        <TextField source="id" />
        <TextField source="name" label="Name" />
        <TextField source="confirmation_number" label="Confirmation" />
        <BooleanField source="confirmed" label="Confirmed" />
        <TextField source="display_financial_status" label="Status" />
        <ReferenceField source="customer_id" reference="user" label="User">
          <TextField source="display_name" />
        </ReferenceField>
        <DateField source="processed_at" showTime locales="de-AT" />
      </SimpleShowLayout>
    </Show>
  );
};

OrderViewEmbedded.propTypes = {
  record: PropTypes.object,
  resource: PropTypes.string,
  id: PropTypes.string,
};

export default OrderViewEmbedded;
