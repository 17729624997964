import { Datagrid, DateField, List, ReferenceField, ReferenceInput, SelectInput, TextField } from 'react-admin';

import SubscriptionViewEmbedded from './SubscriptionViewEmbedded';

const SubscriptionList = () => {
  return (
    <List
      filters={[
        <ReferenceInput alwaysOn source="user_id" reference="user" label="User">
          <SelectInput source="display_name" />
        </ReferenceInput>,
      ]}
      sort={{ field: 'id', order: 'DESC' }}
    >
      <Datagrid expand={<SubscriptionViewEmbedded />} rowClick="expand" optimized bulkActionButtons={false}>
        <TextField source="id" />
        <ReferenceField source="customer_id" reference="user" link="show" label="User">
          <TextField source="display_name" />
        </ReferenceField>
        <TextField source="product_name" label="Product name" />
        <TextField source="billing_interval" label="Interval" />
        <TextField source="status" label="Status" />
        <DateField source="start_date" showTime locales="de-AT" />
        <DateField source="end_date" showTime locales="de-AT" />
        {/* <DateField source="date_modified_gmt" showTime locales="de-AT" /> */}
      </Datagrid>
    </List>
  );
};

export default SubscriptionList;
