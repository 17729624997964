import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';
import GuestAppServiceOrderShow from './GuestAppServiceOrderShow';
import GustAppServiceOrderList from './GustAppServiceOrderList';

export default {
  list: GustAppServiceOrderList,
  icon: AddShoppingCartIcon,
  show: GuestAppServiceOrderShow,
  recordRepresentation: (record: any) => `${record.id}`,
};
